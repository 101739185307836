<script setup lang="ts">
import { cx } from 'class-variance-authority'
import { Motion } from 'motion/vue'

const props = defineProps<{
  pattern: string
}>()

const rows = props.pattern.split('\n').filter(Boolean)

function borders(row: number, cell: number) {
  let classes = new Set()

  const current = rows[row].charAt(cell)
  const above = rows[row - 1]?.charAt(cell)
  const below = rows[row + 1]?.charAt(cell)
  const left = rows[row].charAt(cell - 1)
  const right = rows[row].charAt(cell + 1)

  classes.add('border-l')
  classes.add('border-t')
  if (current !== ' ' && !below) classes.add('border-b')

  if (current === ' ' && (left === ' ' || !left)) classes.delete('border-l')
  if (current === ' ' && (above === ' ' || !above)) classes.delete('border-t')
  if (current !== ' ' && !right) classes.add('border-r')
  if (current !== ' ' && !left) classes.add('border-l')
  if (current === 'X') {
    classes.delete('border-l')
    classes.delete('border-r')
    classes.delete('border-b')
    classes.delete('border-t')
  }

  return Array.from(classes).join(' ')
}
</script>

<template>
  <div class="flex flex-col items-center">
    <Motion
      class="flex justify-center"
      v-for="(row, i) of rows"
      :key="row"
      :initial="{ y: -16, opacity: 0 }"
      :animate="{ y: 0, opacity: 1 }"
      :transition="{ delay: i / 25, easing: [0.16, 1, 0.3, 1] }"
    >
      <div
        v-for="(cell, j) of row.split('')"
        :key="cell"
        :class="[
          'cell-size relative border-gray-200/75 transition duration-500',
          'hover:z-10 hover:!scale-125 hover:border hover:bg-gray-100 hover:shadow-lg hover:duration-75',
          'h-full w-full',
          borders(i, j),
          cell === '2' ? 'bg-gray-100' : '',
        ]"
      />
    </Motion>
  </div>
</template>

<style>
.cell-size {
  width: calc((100vw - 32px) / 12) !important;
  height: calc((100vw - 32px) / 12) !important;
}

@media screen and (min-width: 400px) {
  .cell-size {
    width: calc((100vw - 32px) / 14) !important;
    height: calc((100vw - 32px) / 14) !important;
  }
}
@media screen and (min-width: 462px) {
  .cell-size {
    width: calc((100vw - 32px) / 16) !important;
    height: calc((100vw - 32px) / 16) !important;
  }
}

@media screen and (min-width: 480px) {
  .cell-size {
    width: calc((100vw - 32px) / 18) !important;
    height: calc((100vw - 32px) / 18) !important;
  }
}
@media screen and (min-width: 560px) {
  .cell-size {
    width: calc((100vw - 32px) / 18) !important;
    height: calc((100vw - 32px) / 18) !important;
  }
}

@media screen(sm) {
  .cell-size {
    width: calc((100vw - 32px) / 24) !important;
    height: calc((100vw - 32px) / 24) !important;
  }
}
@media screen(md) {
  .cell-size {
    width: calc((100vw - 64px) / 28) !important;
    height: calc((100vw - 64px) / 28) !important;
  }
}

@media screen and (min-width: 1190px) {
  .cell-size {
    width: calc((100vw - 64px) / 30) !important;
    height: calc((100vw - 64px) / 30) !important;
  }
}
@media screen(xl) {
  .cell-size {
    width: calc((theme(screens.xl) - 64px) / 30) !important;
    height: calc((theme(screens.xl) - 64px) / 30) !important;
  }
}
</style>
